// @mui
import { Box, BoxProps, Button, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as WideLogoSVG } from 'src/assets/logos/logo-wide.svg';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function ClickwableWideLogo({ disabledLink = false, sx }: Props) {

  const logo = (
    <IconButton sx={{ width: 150, height: 40, ...sx }} style={{borderRadius: 8}}>
      Mist
    </IconButton>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
