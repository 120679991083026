// components
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { ReactComponent as LogoSVG } from 'src/assets/logos/logo.svg';

// ----------------------------------------------------------------------


const sidebarConfig = [
  {
    subheader: 'Explore',
    items: [
      { title: 'About Us', path: '/about', icon: <InfoOutlinedIcon/> }
    ],
  },
];

export default sidebarConfig;
