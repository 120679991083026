import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SubmitDeleteDialogProps {
  open: boolean;
  handleClose: VoidFunction;
  handleConfirmDelete: VoidFunction;
}

export default function SubmitDeleteDialog( {open, handleClose, handleConfirmDelete}: SubmitDeleteDialogProps ) {

  return (
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" color="error">
            Would you really like to delete this post?
          </Typography>
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Nope, keep my message.</Button>
          <Button color="error" onClick={handleConfirmDelete}>Yes, continue with deletion.</Button>
        </DialogActions>
      </Dialog>
  );
}
